<template>
  <div style="display: none;">
    <slot v-if="ready"></slot>
  </div>
</template>

<script>
  import { MarkerClusterGroup, MarkerCluster } from 'leaflet.markercluster'
  import { findRealParent, propsBinder } from 'vue2-leaflet'
  import { DomEvent } from 'leaflet'

  const props = {
    options: {
      type: Object,
      default() { return {}; },
    },
  };

  export default {
    props,
    data() {
      return {
        ready: false,
      };
    },
    mounted() {
      this.mapObject = new MarkerClusterGroup(this.options);
      DomEvent.on(this.mapObject, this.$listeners);
      propsBinder(this, this.mapObject, props);
      this.ready = true;
      this.parentContainer = findRealParent(this.$parent);
      this.parentContainer.addLayer(this);
      this.$nextTick(() => {
        this.$emit('ready', this.mapObject);
      });
    },
    beforeDestroy() {
      this.parentContainer.removeLayer(this);
    },
    methods: {
      addLayer(layer, alreadyAdded) {
        if (!alreadyAdded) {
          this.mapObject.addLayer(layer.mapObject);
        }
      },
      removeLayer(layer, alreadyRemoved) {
        if (!alreadyRemoved) {
          this.mapObject.removeLayer(layer.mapObject);
        }
      },
      findMarker(marker){
        this.fireEventOnMarkerOrVisibleParentCluster(marker, 'mouseover');
      },
      fireEventOnMarkerOrVisibleParentCluster(marker, eventName) {
        if (eventName === 'mouseover') {
          var visibleLayer = this.mapObject.getVisibleParent(marker);

          if (visibleLayer instanceof MarkerCluster) {
            /* We want to show a marker that is currently hidden in a cluster. */
            /* Make sure it will get highlighted once revealed. */
            this.mapObject.once('spiderfied', function() {
              marker.fire(eventName);
            });
            /* Now spiderfy its containing cluster to reveal it.*/
            /* This will automatically unspiderfy other clusters.*/
            visibleLayer.spiderfy();
          } else {
      /* The marker is already visible, unspiderfy other clusters if
      they do not contain the marker. */
      this.unspiderfyPreviousClusterIfNotParentOf(marker);
      marker.fire(eventName);
    }
  } else {
    /* For mouseout, marker should be unclustered already, unless*/
    /* the next mouseover happened before?*/
    marker.fire(eventName);
  }
},
unspiderfyPreviousClusterIfNotParentOf(marker) {
  /* Check if there is a currently spiderfied cluster.*/
  /* If so and it does not contain the marker, unspiderfy it.*/
  var spiderfiedCluster = this.mapObject._spiderfied;

  if (spiderfiedCluster && !this.clusterContainsMarker(spiderfiedCluster, marker)) {
    spiderfiedCluster.unspiderfy();
  }
},
clusterContainsMarker(cluster, marker) {
  var currentLayer = marker;

  while (currentLayer && currentLayer !== cluster) {
    currentLayer = currentLayer.__parent;
  }

  /* Say if we found a cluster or nothing.*/
  return !!currentLayer;
}
}
};
</script>
